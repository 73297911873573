import './page-explore.scss';

import { graphql } from 'gatsby';
import React from 'react';

import PageHeader from '../components/PageHeader/PageHeader';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import PageSectionCatalogue from '../components/WithQuery/PageSectionCatalogue/PageSectionCatalogue';
import { PageComponent } from '../types/gatsby.types';
import joinClassNames from '../utils/className.utils';

export const query = graphql`
query ($id: String) {
  wpPage(id: { eq: $id }) {
    ...PageWithFeaturedImageFragment
  }
}
`;

type PageExploreProps = {
  data: any,
}

const PageExplore: PageComponent<PageExploreProps> = props => {
  const {
    data: {
      wpPage: { id, title, content, slug, isFrontPage, featuredImage, seo },
    },
  } = props;

  return (
    <PageTemplate
      {...props} // always include
      wpYoastSEO={seo}
      className={joinClassNames(slug)}
      pageSlug={slug}
      title={title}
      isFrontPage={isFrontPage}
      featuredMedia={featuredImage?.node}
    >

      <PageHeader title={title} hasImage={featuredImage?.node} />

      <PageSectionCatalogue
        pageSlug={slug}
        // catalogueType={slug === 'corporate-ireland' ? 'corporate' : slug === 'explore-ireland' ? 'explore' : undefined}
      />

    </PageTemplate>
  )
}

export default PageExplore;