import { graphql, useStaticQuery } from 'gatsby';
import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import Scroll from 'react-scroll';
import { globalHistory } from '@reach/router';

import "swiper/css";

import APP_CONTENT_CONFIG from '../../../constants/appContentConfig.constants';
import { useOnMount } from '../../../hooks/lifecycle.hooks';
import { Category } from '../../../types/wordpress.types';
import { useProps, useStore } from '../../../utils/mobx.utils';
import ProductCard from '../../ProductCard/ProductCard';
import { makeParamPairs } from '../../../utils/urlParams.utils';
import { ID_OverlayProduct } from '../../OverlayProduct/OverlayProduct';
import BaseLink from '../../BaseLink/BaseLink';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper';
import { useControllers } from '../../../controllers/app.controller';
import useAllWpProduct from '../../../content/AllWpProduct/AllWpProduct';
import './PageSectionCatalogue.scss';
import joinClassNames from '../../../utils/className.utils';

type PageSectionCatalogueProps = {
  pageSlug: string,
  // catalogueType?: string | 'corporate' | 'explore', // aka collection type.
}

const pageSlugToPageConfig = APP_CONTENT_CONFIG.page.explore.pageSlugToPageConfig;

const PageSectionCatalogue: React.FC<PageSectionCatalogueProps> = props => {
  const { catalogueCategoryData } = useStaticQuery(graphql`
  {
    catalogueCategoryData: allWpCategory {
      totalCount
      nodes {
        id
        name
        uri
        slug
      }
    }
    catalogueProductData: allWpProduct(
      filter: {status: {eq: "publish"}}
      sort: {order: ASC, fields: date}
    ) {
      edges {
        node {
          ...ProductWithCategoriesAndProductFieldsFragment
        }
      }
    }
  }
`);

  const products = useAllWpProduct();
  const categories: Category[] = catalogueCategoryData.nodes;

  const { UI } = useControllers();
  const p = useProps(props);
  const s = useStore(() => ({
    page: (p.pageSlug ?? 'explore') as keyof typeof pageSlugToPageConfig,
    shouldClearView: false,
    viewingCategorySlug: null as string | null,
    viewingProductSlug: null as string | null,
    get categories() {
      return categories;
    },
    get products() {
      return products.filter(product => product.productFields.collection.includes('explore'));
      // return products.filter(product => product.productFields.collection.includes(p.pageSlug ?? 'explore'));
    },
    get productConfig() { return pageSlugToPageConfig[s.page]?.productCardConfig; },
    get categoriesToShow() {
      return pageSlugToPageConfig[s.page]?.categoryDefs.filter(c => s.categories.find(cat => cat.slug === c.slug)) ?? [];
    },
    get viewingCategory() {
      return s.categories.find(c => c.slug === s.viewingCategorySlug);
    },
    get eligibleProducts() {
      return s.products.filter(p => p.categories.nodes.find(cat => s.categoriesToShow.find(c => c.slug === cat.slug)));
    },
    get viewingCategorySliderIndex() {
      const idx = s.categoriesToShow.findIndex(c => c.slug === s.viewingCategorySlug);
      return idx === -1 ? 0 : idx + 1;
    },
    get filteredProducts() {
      return s.viewingCategorySlug ? s.eligibleProducts.filter(p => p.categories.nodes.find(cat => cat.slug === s.viewingCategorySlug)) : s.eligibleProducts;
    },
    getHashFromWindowLocation: action(() => {
      const [categorySlug, hashParamStr, ..._] = window.location.hash.split('?');
      const paramStr = hashParamStr ? hashParamStr : window.location.search;
      const paramPairs = paramStr.startsWith('?') ? paramStr.replace('?', '') : paramStr;
      const params = makeParamPairs(paramPairs?.split('&') ?? [])
      s.viewingCategorySlug = categorySlug?.substring(1);
      s.viewingProductSlug = params[ID_OverlayProduct];
    }),
    focusOnCurrentLink: async () => {
      if (window.innerWidth >= 640) {
        // scroll the page
      } else {
        // scroll the nav
        s.swiper?.slideTo(s.viewingCategorySliderIndex);
      }
    },
    scrollToTop: async () => {
      Scroll.animateScroll.scrollToTop({
        duration: 380,
      });
    },
    handleCategoryLinkClick: action((viewingCategorySlug: string | null) => {
      s.viewingProductSlug = null;
      s.viewingCategorySlug = viewingCategorySlug;
    }),
    _swiper: null as Swiper | null,
    get swiper() { return s._swiper },
    set swiper(v: Swiper | null) { s._swiper = v; }
  }))

  useOnMount(() => {
    const disposeCategorySlugWatcher = reaction(
      () => s.viewingCategorySlug,
      action(() => {
        s.shouldClearView = true;
        s.scrollToTop();
        s.focusOnCurrentLink();
        setTimeout(action(() => {
          s.shouldClearView = false;
        }), 50);
      })
    )

    const handleHashChange = () => {
      s.getHashFromWindowLocation();
      s.focusOnCurrentLink();
    }

    const historySubscriber = globalHistory.listen(handleHashChange);
    s.getHashFromWindowLocation();
    return () => {
      historySubscriber();
      disposeCategorySlugWatcher();
    }
  })

  const CreateCategoryNavigation = (withSwiper: boolean) => {
    return <>
      {AllCategoryLink(withSwiper)}
      {s.categoriesToShow.map((category, idx) => {
        const key = `CategoryLink-${idx}`;
        return <React.Fragment key={key}>
          {CreateCategoryLink(withSwiper, {
            key,
            to: `#${category.slug}`,
            className: s.viewingCategory && s.viewingCategory.slug === category.slug ? 'active' : "",
            // onClick: () => s.handleCategoryLinkClick(category.slug),
            children: <span>{category.name}</span>,
          })}
        </React.Fragment>
      })}
    </>
  }
  const CreateCategoryLink = (withSwiper: boolean, linkProps: { key?: string, className?: string, to: string, onClick?: () => void, children: JSX.Element }) => {
    const CategoryLinkComponent = <BaseLink {...linkProps} />;
    return withSwiper
      ? <SwiperSlide key={linkProps.key} className="CategoryNavListItem">{CategoryLinkComponent}</SwiperSlide>
      : <li className="CategoryNavListItem">{CategoryLinkComponent}</li>;
  }
  const AllCategoryLink = (withSwiper: boolean) => {
    return CreateCategoryLink(withSwiper, {
      className: !s.viewingCategory ? "active" : "",
      to: "#",
      // onClick: () => s.handleCategoryLinkClick(null),
      children: <span>All</span>,
    });
  }

  return <Observer children={() => (
    <section className="page-section PageSectionCatalogue">
      <div className="container">
        <p className="PageSectionCatalogue__categories-nav__mobile-title">Filter by</p>
        <nav className="PageSectionCatalogue__categories-nav" id="category-nav">
          {UI.onlyPhones
            ? <ReactSwiper
              onSwiper={(swiper) => s.swiper = swiper}
              {...{
                centeredSlides: true,
                slidesPerView: "auto",
                spaceBetween: 30,
                direction: 'horizontal',
                className: "CategorySwiper CategoryNavList",
              }}
            >
              {CreateCategoryNavigation(true)}
            </ReactSwiper>
            : <ul id="CategoryNavList" className="CategoryNavList">
              {CreateCategoryNavigation(false)}
            </ul>
          }
        </nav>
        <ul className={joinClassNames("PageSectionCatalogue__product-grid", s.productConfig?.orientation)}>
          {s.filteredProducts.length === 0 && <h3 className="PageSectionCatalogue__product-grid-empty-notice">
            <span>Nothing in this category yet.</span>
          </h3>}
          {!s.shouldClearView &&
            s.filteredProducts.map((product) => (
              <li key={product.id}>
                <ProductCard
                  product={product}
                  canViewDetails
                  showPrice
                  shouldAnimateIn
                  openProductOverlayOnMount={s.viewingProductSlug === product.slug}
                  {...s.productConfig}
                />
              </li>
            ))
          }
        </ul>
      </div>
    </section>
  )} />
}

export default PageSectionCatalogue;